/* Room */
/* 이미지 슬라이더 점들 변경 */
.custom-slider .slick-dots {
  position: absolute;
  bottom: 20px;
}
.custom-slider .slick-dots li button:before {
  color: white;
}
.custom-slider .slick-dots li {
  margin: 0 -2px;
}

/* RoomSearchBar - DatePicker */
.custom-date-picker {
  background-color: transparent !important;
}
.custom-date-picker:focus {
  outline: none;
  box-shadow: none;
}
