:root {
  --fc-small-font-size: 0.85em;

  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: hsla(0, 0%, 82%, 0.3);
  --fc-neutral-text-color: grey;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;

  --fc-button-bg-color: #f3af00;
  --fc-button-border-color: #f3af00;

  --fc-button-hover-bg-color: #f8cf66;
  --fc-button-hover-border-color: #f8cf66;

  --fc-button-active-bg-color: #f8cf66;
  --fc-button-active-border-color: #f8cf66;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: hsla(0, 0%, 84%, 0.3);

  --fc-bg-event-color: #8fdf82;
  --fc-bg-event-opacity: 0.3;

  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);

  --fc-now-indicator-color: red;

  --fc-daygrid-event-dot-width: 8px;
}

.fc {
  margin: 20px auto;
}

/* 요일 행 높이지정 */
.fc .fc-scrollgrid-section table {
  height: 40px;
}

/* 요일 행 TEXT 중간 정렬 */
.fc .fc-col-header-cell {
  vertical-align: middle;
}
/* 타임 그리드에서의 이벤트 */
.fc-event {
  cursor: pointer;
  padding: 0 0 3px 5px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}
/* 타임 그리드 하루종일 일정 */
.fc-scrollgrid-shrink {
  height: 70px;
}
/* 타임 그리드 30분당 일정 */
.fc .fc-timegrid-slot-label {
  height: 50px;
}
/* 타임 그리드(주별 & 일별)에서 시간 TEXT */
.fc-timegrid-event .fc-event-time {
  font-size: "9px";
  color: #727272;
}
/* 데이 그리드(월별)에서 시간 TEXT */
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  font-size: "9px";
  color: #727272;
}
/* 타임 그리드(주별 & 일별)에서 내용 TEXT */
.fc-timegrid-event .fc-event-title {
  font-size: "9px";
  color: black;
}
/* 데이 그리드(월별)에서 내용 TEXT */
.fc-daygrid-block-event .fc-event-title {
  font-size: "9px";
  color: black;
}
.fc-event-main-frame {
  border-radius: 20px;
}
/* 요일별 일자 색상 */
.fc-day-sun a {
  color: red;
}
.fc-day-sat a {
  color: gray;
}
/* 오늘 날짜 배경색*/
.fc .fc-daygrid-day.fc-day-today {
  background-color: #cccccc38;
  color: #356eff;
}

@media only screen and (max-width: 600px) {
  .fc .fc-toolbar-title {
    font-size: 1.4rem;
  }
  .fc .fc-button-group {
    font-size: 0.8rem;
  }
  .fc .fc-today-button,
  .fc .fc-prev-button,
  .fc .fc-next-button,
  .fc .fc-dayGridMonth-button {
    font-size: 0.8rem;
  }
}
